import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
   providedIn: 'root'
})
export class AuthGuard {
   constructor(
      private _authService: AuthService,
      private _router: Router
   ) {}

   canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser = this._authService.currentUserValue;
      if (currentUser && !this._authService.isTokenExpired) {
         return true;
      }

      // navigate to login page
      this._router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
      // you can save redirect url so after authing we can move them back to the page they requested
      return false;
   }
}
